import { GoogleAuthProvider, onAuthStateChanged, signInWithPopup, signOut } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { auth } from "../config/firebase";

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});

  // Add email verification here
  const allowedEmails = [
    "chris@satori-ai.com",
    "eden@satori-ai.com",
    "lucas.catalan.galan@gmail.com",
    "m-a-r-k@m-o-n-i-k-a.com",
    "j-a-c-k@m-o-n-i-k-a.com",
  ];
  const googleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Add allowed emails check
      if (!allowedEmails.includes(user.email)) {
        await signOut(auth); // Sign them out immediately
        throw new Error("not authorized");
      }

      return user;
    } catch (error) {
      await signOut(auth); // Make sure user is signed out in case of any error
      throw error; // Re-throw the error to be handled by the component
    }
  };

  const logOut = () => {
    signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, currentUser => {
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return <AuthContext.Provider value={{ googleSignIn, logOut, user }}>{children}</AuthContext.Provider>;
};

export const UserAuth = () => {
  return useContext(AuthContext);
};
